
import ApexCharts from 'apexcharts';
import { defineComponent, ref } from "vue";
import ChartDownload from "@components/dashboard/ChartDownload.vue";
import RadialChartLegend from "@components/dashboard/DonutChartLegend.vue";
import Utilities from '@utilities/Utilities';
import { QUERY, MAP } from "./OverviewIntegrationStatusQueries"
import { useLazyQuery } from '@vue/apollo-composable';
import DashboardSkeleton from '@components/skeleton/DashboardSkeleton.vue';
import { IChartData } from "@components/dashboard/types/IDonutChartData";


export default defineComponent({
    name: "OverviewIntegrationStatus",
    components: { ChartDownload, RadialChartLegend, DashboardSkeleton },
    props: ["componentOptions"],
    data() {
        const query = useLazyQuery(QUERY);
        let chartData: IChartData | undefined;

        return {
            query,
            chartData,
            chart: ref<ApexCharts | null>(null),
            options: {},
            id: Utilities.getIdGenerator().generateV4(),
            getColorsArray: (size: number) => { Utilities.getColors().getColorsArrayBySize(size) },
            positiveSeriesValues: 0
        };
    },
    mounted() {
        this.query.onResult((qRes) => {
            if (qRes instanceof Error) { return; }

            const res = qRes.data.me.site.analytics.integrationStatus;

            const data: IChartData = {
                series: [],
                type: this.componentOptions.type,
                labels: []
            }

            const labels = MAP;

            const keys = Object.keys(res);
            const rawLabels: string[] = [];
            for (const index in keys) {
                rawLabels.push(keys[index]);
            }

            for (const index in keys) {
                const key = keys[index];
                if (labels[key] !== undefined) {
                    if (res[key] > 0) {
                        this.positiveSeriesValues++;
                    }
                    data.series.push(res[key]);
                    data.labels.push(labels[key]);
                }
            }


            let type = 'donut';
            switch (data.type) {
                case 'DonutChart':
                    type = 'donut';
                    break;
                case 'PolarAreaChart':
                    type = 'polarArea';
                    break;
            }

            this.chartData = { ...data };
            const options = {
                headerLegendName: "Status",
                headerValueName: "Product Count",
                ...this.chartData,
                chart: {
                    type: type,
                    width: '100%',
                    toolbar: {
                        show: false,
                    },
                },
                legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'left',
                    markers: {
                        fillColors: this.getColorsArray(this.chartData.series.length),
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                    style: {
                        fontSize: "18px",
                    }
                },
                fill: {
                    colors: this.getColorsArray(this.chartData.series.length),
                    opacity: 1,
                },
                colors: this.getColorsArray(this.chartData.series.length),
                plotOptions: {
                    polarArea: {
                        rings: {
                            strokeWidth: 0,
                        },
                        spokes: {
                            strokeWidth: 0,
                        }
                    },
                },
                yaxis: {
                    show: false,
                },
            };

            this.options = options;

            this.$nextTick(() => {
                this.setupChart();
                this.renderChart();

                if (this.positiveSeriesValues === 0 && this.chart !== null) {
                    this.chart.updateOptions({
                        series: [1],
                        labels: [],
                        fill: {
                            colors: ['#f0f0f0'],
                        },
                        tooltip: {
                            enabled: false
                        },
                        states: {
                            normal: {
                                type: 'none',
                                value: 0
                            },
                            hover: {
                                filter: 'none',
                                value: 0
                            },
                            active: {
                                filter: 'none',
                                value: 0
                            }
                        }
                    });
                }
            })
        });

        this.query.load();
    },
    methods: {
        setupChart: function (): void {
            const chartElement: HTMLElement | null = document.getElementById(this.id);

            if (chartElement === null) {
                return;
            }

            this.chart = new ApexCharts(chartElement, this.options);
            return;
        },
        renderChart: function (): void {
            if (this.chart === null) {
                return;
            }

            this.chart.render();
            return;
        },
    },
});
