import { gql } from "graphql-tag";

const QUERY = gql`
    query {
        me {
            site {
                analytics {
                    inventoryType {
                        simple
                        merged
                        bundled
                    }
                }
            }
        }
    }
`;

const MAP = {
    simple: "Simple",
    merged: "Merged",
    bundled: "Bundled",
};

export { QUERY, MAP };
