
import ApexCharts from 'apexcharts';
import { defineComponent, ref, inject } from "vue";
import ChartDownload from "@components/dashboard/ChartDownload.vue";
import BarChartLegend from "@components/dashboard/BarChartLegend.vue";
import Utilities from '@utilities/Utilities';
import Container from 'typedi';
import ConfigsService from '@services/configs/Configs.service';
import LocalStorageService from '@services/local-storage/LocalStorage.service';
import dayjs from 'dayjs';
import axios from 'axios';
import DashboardSkeleton from "@components/skeleton/DashboardSkeleton.vue";
import Countup from "@components/Countup.vue";

interface IBarChartSeries {
    name: string;
    data: number[];
}
interface IBarChartStatistics {
    currency: string;
    categories: Array<string>;
    series: IBarChartSeries[];
    total: number;
}

export default defineComponent({
    name: "OverviewTotalSalesPerChannel",
    components: {
        ChartDownload,
        BarChartLegend,
        DashboardSkeleton,
        Countup
    },
    props: ["componentOptions"],
    data() {
        let options, isAverage, $mitt;

        return {
            chart: ref<ApexCharts | null>(null),
            options,
            id: Utilities.getIdGenerator().generateV4(),
            isAverage,
            loaded: false,
            getColorsArray: (size: number) => { Utilities.getColors().getColorsArrayBySize(size) },
            $mitt
        };
    },
    mounted() {
        this.$mitt = inject('$mitt');
        this.$mitt.on("dashboard-date-update", () => { this.loadComponent() })

        this.loadComponent();
    },
    beforeUnmount() {
        this.$mitt.all.delete('dashboard-date-update', () => { this.loadComponent() });
    },
    computed: {
        getChartSize(): string {
            switch (this.componentOptions.options.chartSize) {
                case '50%':
                    return 'mo-w-50';
                case '100%':
                    return 'w-100';
                default:
                    return 'w-100';
            }
        },
        getIsLegendEnabled(): boolean {
            return this.componentOptions.options.isChartLegendEnabled;
        }
    },
    methods: {
        loadComponent() {
            this.loaded = false;
            this.getStatistics().then((res) => {

                if (res instanceof Error) { return; }

                const chartData = {
                    currency: res.currency,
                    series: res.series,
                    categories: res.categories,
                    total: res.total
                }

                const categories = chartData.categories;

                let type = 'bar';
                let isStacked = true;
                let columnWidth = '25%';
                let chartHeight = '300px';

                switch (this.componentOptions.type) {
                    case 'StackedBarChart':
                        type = 'bar';
                        isStacked = true;
                        columnWidth = '25%';
                        break;
                    case 'DoubleBarChart':
                        type = 'bar';
                        isStacked = false;
                        columnWidth = '35%';
                        break;
                    case 'AverageBarChart':
                        type = 'bar';
                        isStacked = false;
                        columnWidth = '35%';
                        break;
                    case 'AverageSplineChart':
                        type = 'area';
                        isStacked = false;
                        columnWidth = '35%';
                        break;
                }

                switch (this.componentOptions.options.chartSize) {
                    case '100%':
                        chartHeight = '300px';
                        break;
                    case '50%':
                        chartHeight = '179px';
                        break;
                }

                const options = {
                    ...chartData,
                    chart: {
                        type: type,
                        stacked: isStacked,
                        height: chartHeight,
                        width: '100%',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: ['#AEACBC'],
                            },
                            formatter: (value) => `${chartData.currency} ${parseFloat(String(value)).toFixed(2)}`,
                        },
                    },
                    xaxis: {
                        categories,
                        strokeDashArray: 3,
                        labels: {
                            show: true,
                            style: {
                                cssClass: 'apexcharts-xaxis-label',
                            },
                        },
                    },
                    legend: {
                        show: false,
                        position: 'top',
                        horizontalAlign: 'left',
                        markers: {
                            fillColors: this.getColorsArray(chartData.series.length),
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        style: {
                            fontSize: "18px",
                        }
                    },
                    fill: {
                        opacity: 1,
                        colors: this.getColorsArray(chartData.series.length),
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: columnWidth,
                            borderRadius: 5,
                        },
                    },
                }
                this.options = options;

                this.loaded = true;
                this.$nextTick(() => {
                    this.setupChart();
                    this.renderChart();
                })
            });
        },
        async getStatistics(): Promise<IBarChartStatistics | Error> {
            return new Promise((resolve, reject) => {
                const _configService = Container.get(ConfigsService);
                const _localStorageService = Container.get(LocalStorageService);

                const apiV1Url = _configService.getByEnv().v1API;
                const accessToken = _localStorageService.getUserToken();
                const userId = _localStorageService.getUserId();
                let siteId = null;
                let currency = "";

                let [fromDate, toDate] = JSON.parse(sessionStorage.getItem("dashboard-date-period") || '[]');
                const dateRangeDiff = dayjs(toDate).diff(fromDate, "days");
                const fromDateMonthDays = dayjs(fromDate).daysInMonth();
                const categories: any = [];
                const series: IBarChartSeries[] = [];
                const defaultData: any = [];

                const isOneDay = dateRangeDiff === 0;
                const isWeek = dateRangeDiff >= 0 && dateRangeDiff <= 6;
                const isOneMonth = dateRangeDiff >= 7 && (dayjs(fromDate).format("YYYYMM") === dayjs(toDate).format("YYYYMM") ? true : dateRangeDiff < fromDateMonthDays);

                //pushes categories according to date range
                if (isOneDay) {
                    fromDate = dayjs(fromDate).startOf("day").toString();
                    toDate = dayjs(toDate).endOf("day").toString();
                    for (let i = 0; i < 24; i++) {
                        categories.push(`${i < 10 ? 0 : ""}${i}:00`)
                        defaultData.push(0);
                    }
                } else if (isWeek) {
                    let offset = 0;
                    while (dayjs(dayjs(fromDate).add(offset - 1, "day")).diff(toDate, "day") < 0) {
                        categories.push(dayjs(fromDate).add(offset, "day").format("ddd"));
                        defaultData.push(0);
                        offset++;
                    }
                } else if (isOneMonth) {
                    let offset = 0;
                    while (dayjs(dayjs(fromDate).add(offset - 1, "day")).diff(toDate, "day") < 0) {
                        categories.push(dayjs(fromDate).add(offset, "day").format("DD"));
                        defaultData.push(0);
                        offset++;
                    }
                } else {
                    let offset = 0;
                    while (dayjs(dayjs(fromDate).add(offset, "month")).diff(toDate, "day") <= 0) {
                        categories.push(dayjs(fromDate).add(offset, "month").format("MMM"));
                        defaultData.push(0);
                        offset++;
                    }
                }

                const headers = {
                    accept: "application/json, text/plain, */*",
                    "content-type": "application/json;charset=UTF-8",
                    "accept-language": "en-US,en;q=0.9",
                    authorization: accessToken,
                };

                axios
                    .get(`${apiV1Url}/simpleUsers/${userId}/sites`, { headers })
                    .then(async (resp) => {
                        siteId = resp.data[0].id;
                        currency = resp.data[0].siteCurrency.code;
                        const credentials = await axios.get(
                            `${apiV1Url}/sites/${siteId}/marketplaceCredentials`,
                            {
                                headers,
                                params: {
                                    filter: {
                                        fields: ["id", "name"]
                                    }
                                }
                            }
                        ).then(resp => resp.data)
                        const orders = await axios.get(
                            `${apiV1Url}/sites/${siteId}/marketplaceOrders`,
                            {
                                headers,
                                params: {
                                    filter: {
                                        where: {
                                            purchased: {
                                                between: [
                                                    fromDate,
                                                    toDate
                                                ],
                                            },
                                            and: [
                                                {
                                                    or: [
                                                        { isMerged: false },
                                                        { isMergedParent: true },
                                                    ],
                                                },
                                            ],
                                            systemStatus: {
                                                nin: ["cancelled", "system"],
                                            },
                                        },
                                        fields: [
                                            "id",
                                            "purchased",
                                            "price",
                                            "marketplaceCustomerId",
                                            "marketplaceId",
                                            "status",
                                            "systemStatus",
                                            "marketplaceCredentialId",
                                            "priceCurrency",
                                            "systemStatus",
                                        ],
                                    },
                                },
                            }
                        ).then((resp) => resp.data);

                        const credsHash = {};
                        credentials.forEach(creds => {
                            credsHash[creds.id] = { name: creds.name, data: [...defaultData] };
                        })

                        //pushes data according to date range
                        orders.forEach(order => {
                            if (isOneDay) {
                                const orderHour = parseInt(dayjs(order.puchased).format("HH"));
                                if (credsHash[order.marketplaceCredentialId]) {
                                    credsHash[order.marketplaceCredentialId].data[orderHour] += order.price / 100;
                                }
                            } else if (isWeek) {
                                let offset = 0;
                                let orderDay: any = null;
                                const orderDate = parseInt(dayjs(order.purchased).format("YYYYMMDD"));
                                let found = false;
                                while (dayjs(dayjs(fromDate).add(offset, "day")).diff(toDate, "day") <= 0 && !found) {
                                    if (parseInt(dayjs(fromDate).add(offset, "day").format("YYYYMMDD")) === orderDate) {
                                        found = true;
                                        orderDay = offset;
                                    }
                                    offset++;
                                }
                                if (orderDay !== null) {
                                    if (credsHash[order.marketplaceCredentialId]) {
                                        credsHash[order.marketplaceCredentialId].data[orderDay] += order.price / 100;
                                    }
                                }
                            } else if (isOneMonth) {
                                let offset = 0;
                                let orderDay: any = null;
                                const orderDate = parseInt(dayjs(order.purchased).format("YYYYMMDD"));
                                let found = false;
                                while (dayjs(dayjs(fromDate).add(offset - 1, "day")).diff(toDate, "day") < 0) {
                                    if (parseInt(dayjs(fromDate).add(offset, "day").format("YYYYMMDD")) === orderDate) {
                                        found = true;
                                        orderDay = offset;
                                    }
                                    offset++;
                                }
                                if (orderDay !== null) {
                                    if (credsHash[order.marketplaceCredentialId]) {
                                        credsHash[order.marketplaceCredentialId].data[orderDay] += order.price / 100;
                                    }
                                }
                            } else {
                                let offset = 0;
                                let orderMonth: any = null;
                                const orderDate = parseInt(dayjs(order.purchased).format("YYYYMM"));
                                let found = false;
                                while (dayjs(dayjs(fromDate).add(offset, "month")).diff(toDate, "month") <= 0 && !found) {
                                    if (parseInt(dayjs(fromDate).add(offset, "month").format("YYYYMM")) === orderDate) {
                                        found = true;
                                        orderMonth = offset;
                                    }
                                    offset++;
                                }
                                if (orderMonth !== null) {
                                    if (credsHash[order.marketplaceCredentialId]) {
                                        credsHash[order.marketplaceCredentialId].data[orderMonth] += order.price / 100;
                                    }
                                }
                            }
                        })

                        for (const key in credsHash) {
                            series.push(credsHash[key]);
                        }

                        let total = 0;
                        for (const seriesIndex in series) {
                            for (const dataIndex in series[seriesIndex].data) {
                                total += series[seriesIndex].data[dataIndex];
                            }
                        }

                        resolve({
                            currency,
                            categories,
                            series,
                            total,
                        });

                    });
            });
        },
        setupChart: function (): void {
            const chartElement: HTMLElement | null = document.getElementById(this.id);

            if (chartElement === null) {
                return;
            }

            this.chart = new ApexCharts(chartElement, this.options);
            return;
        },
        renderChart: function (): void {
            if (this.chart === null) {
                return;
            }

            this.chart.render();
            return;
        },
    },
});
