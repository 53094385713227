import { gql } from "graphql-tag";

const QUERY = gql`
    query {
        me {
            site {
                analytics {
                    integrationStatus {
                        live
                        integrating
                        errored
                    }
                }
            }
        }
    }
`;

const MAP = {
    live: "Live",
    integrating: "Integrating",
    errored: "Errored",
};

export { QUERY, MAP };
