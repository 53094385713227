
import { defineComponent } from "vue";
import CountUp from "@components/Countup.vue";
import Utilities from '@utilities/Utilities';

export default defineComponent({
    name: "BarChartLegend",
    components: { CountUp },
    props: ["chart", "options"],
    data() {
        const series = this.options.series;
        const seriesSorted = series.sort((a, b) => {
            const aTotal = a.data.reduce((partialSum, a) => partialSum + a, 0);
            const bTotal = b.data.reduce((partialSum, a) => partialSum + a, 0);

            return aTotal < bTotal ? 1 : -1;
        })

        return {
            activeLegends: this.options.series.length,
            seriesSorted
        }
    },
    methods: {
        getChannelTotal(data: number[]): number {
            let total = 0;
            for (const index in data) {
                total += data[index];
            }

            return total;
        },
        getColorsArray: (size: number): string[] => {
            return Utilities.getColors().getColorsArrayBySize(size);
        },
        getColorByIndex: (size: number, index: number): string => {
            return Utilities.getColors().getColorsByIndex(size, index);
        },
        handleGraphColumns(event: Event, index: number, series: string): void {
            const elm: any = this.$refs[`totalSalesPerChannelData-${index}`];
            const legendDisabledClass = "disabled";
            if (event.type === "click") {

                if (this.activeLegends <= 1 && !elm.classList.contains(legendDisabledClass)) {
                    return;
                } else if (elm.classList.contains(legendDisabledClass)) {
                    this.activeLegends++;
                } else {
                    this.activeLegends--;
                }

                this.toggleSeries(elm, legendDisabledClass);
                this.chart.toggleSeries(series);
                this.forceMouseMove(elm);
                return;
            }
            this.highlightSeries(elm, legendDisabledClass, event);
        },
        toggleSeries(element: HTMLElement, legendDisabledClass: string): void {
            element.classList.toggle(legendDisabledClass);
            return;
        },
        highlightSeries(element: HTMLElement, legendDisabledClass: string, event: Event): void {
            if (element.classList.contains(legendDisabledClass)) {
                return;
            }
            this.chart.highlightSeriesOnLegendHover(event, element);
            return;
        },
        forceMouseMove(element: HTMLElement): void {
            element.dispatchEvent(new Event("mousemove"));
            return;
        },
    },
});
