
import { defineComponent, inject } from "vue";
import DashboardInteractor from "@interactors/dashboard/Dashboard.interactor";
import Utilities from "@utilities/Utilities";
import OverviewStatusCounters from "@components/dashboard/OVERVIEW/status-counters/OverviewStatusCounters.vue";
import Container from "typedi";
import OverviewActiveOrderAge from "@components/dashboard/OVERVIEW/active-order-age/OverviewActiveOrderAge.vue";
import OverviewIntegrationStatus from "@components/dashboard/OVERVIEW/integration-status/OverviewIntegrationStatus.vue";
import OverviewInventoryType from "@components/dashboard/OVERVIEW/inventory-type/OverviewInventoryType.vue";
import OverviewTotalSalesPerChannel from "@components/dashboard/OVERVIEW/total-sales-per-channel/OverviewTotalSalesPerChannel.vue";
import OptionsInteractor from "@interactors/options/Options.interactor";

export default defineComponent({
    name: "Dashboard",
    components: {
        OverviewStatusCounters,
        OverviewActiveOrderAge,
        OverviewIntegrationStatus,
        OverviewInventoryType,
        OverviewTotalSalesPerChannel,
    },
    data() {
        let $mitt: any, sectionOptions;

        return {
            $mitt,
            sectionOptions,
            loaded: false,
        };
    },
    async mounted() {
        this.$mitt = inject("$mitt");

        this.sectionOptions = await OptionsInteractor.getSectionOptions();

        this.$mitt.emit("view-loading-finish");
        this.loaded = true;
    },
    methods: {
        getComponentOptions(title: string) {
            for (const index in this.sectionOptions) {
                const componentOptions = this.sectionOptions[index];
                if (componentOptions.title === title) {
                    return componentOptions;
                }
            }

            return undefined;
        },
        generateID(): string {
            const generator = Utilities.getIdGenerator();
            return generator.generateV4();
        },
        getChartComponentName(chartType: string): string | undefined {
            return Container.get(DashboardInteractor).getChartComponentName(
                chartType
            );
        },
    },
});
