
import { defineComponent } from "vue";
import CountUp from "@components/Countup.vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { QUERY, MAP } from "./OverviewStatusCountersQueries";
import DashboardSkeleton from "@components/skeleton/DashboardSkeleton.vue";

interface IChartDataPoint {
    name: string;
    value: number;
    href: string;
}
interface IChartData {
    [dataName: string]: IChartDataPoint;
}

export default defineComponent({
    name: "OverviewStatusCounters",
    components: { CountUp, DashboardSkeleton },
    props: ["componentOptions"],
    data() {
        const query = useLazyQuery(QUERY);
        let chartData: undefined | IChartData;
        return {
            query,
            chartData,
        };
    },
    mounted() {
        this.query.onResult((qRes) => {
            if (qRes instanceof Error) { return; }

            const res = qRes.data.me.site.analytics.activeOrders;

            this.chartData = Object.keys(res).reduce((accumulator, key, index) => {
                if (accumulator[key]) {
                    accumulator[key].value = res[key];
                }

                return accumulator;
            }, { ...MAP });
        });

        this.query.load();
    }
});
