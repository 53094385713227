
import { defineComponent } from "vue";
import BarChartImitation from "./dashboardImitationComponents/BarChartImitation.vue";
import DonutChartImitation from "./dashboardImitationComponents/DonutChartImitation.vue";
import HeatmapChartImitation from "./dashboardImitationComponents/HeatmapChartImitation.vue";
import StatusCountersImitation from "./dashboardImitationComponents/StatusCountersImitation.vue";
import AverageCardsImitation from "./dashboardImitationComponents/AverageCardsImitation.vue";
import DashboardInteractor from "@interactors/dashboard/Dashboard.interactor";
import GeneralCardsImitation from "./dashboardImitationComponents/GeneralCardsImitation.vue";
import Container from "typedi";

export default defineComponent({
    name: "DashboardSkeleton",
    components: { BarChartImitation, DonutChartImitation, HeatmapChartImitation, StatusCountersImitation, AverageCardsImitation, GeneralCardsImitation },
    props: ['options'],
    methods: {
        getComponentCategoryName(chartType: string): string {
            return Container.get(DashboardInteractor).getChartComponentName(chartType) as string;
        },
        getComponentName(componentName: string): string {
            switch (componentName) {
                case "AverageBarChart":
                    return "BarChart"
                default:
                    return componentName;
            }
        },
        getChartSize(size: string, isChartLegendEnabled: boolean, chartCategory: string): string | undefined {
            switch (chartCategory) {
                case 'AverageCards':
                    return 'skeleton-100-25-avg';
                case 'GeneralCards':
                    return 'skeleton-100-25-avg';
                case 'StatusCounters':
                    return 'skeleton-100-25';
                case 'DonutChart':
                    return 'skeleton-50-50';
                case 'HeatmapChart':
                    return 'skeleton-100-50';
                case 'BarChart':
                    if (size === '100%') {
                        if (isChartLegendEnabled) {
                            return 'skeleton-100-125';
                        }

                        return 'skeleton-100-100';

                    }

                    if (isChartLegendEnabled) {
                        return 'skeleton-50-100';
                    }

                    return 'skeleton-50-50';
                case 'AverageBarChart':
                    if (size === '100%') {
                        if (isChartLegendEnabled) {
                            return 'skeleton-100-125';
                        }

                        return 'skeleton-100-100';

                    }

                    if (isChartLegendEnabled) {
                        return 'skeleton-50-100';
                    }

                    return 'skeleton-50-50';
            }
        },
    }
});
