import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-027bfc90")
const _hoisted_1 = {
  key: 0,
  id: "kt_content_container",
  class: "container-xxl dashboard-view py-10"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverviewStatusCounters = _resolveComponent("OverviewStatusCounters")!
  const _component_OverviewTotalSalesPerChannel = _resolveComponent("OverviewTotalSalesPerChannel")!
  const _component_OverviewActiveOrderAge = _resolveComponent("OverviewActiveOrderAge")!
  const _component_OverviewIntegrationStatus = _resolveComponent("OverviewIntegrationStatus")!
  const _component_OverviewInventoryType = _resolveComponent("OverviewInventoryType")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OverviewStatusCounters, {
          componentOptions: 
            _ctx.getComponentOptions('Today\'s Active Order Status')
        
        }, null, 8, ["componentOptions"]),
        _createVNode(_component_OverviewTotalSalesPerChannel, {
          componentOptions: _ctx.getComponentOptions('Total Sales')
        }, null, 8, ["componentOptions"]),
        _createVNode(_component_OverviewActiveOrderAge, {
          componentOptions: _ctx.getComponentOptions('Active Order Age')
        }, null, 8, ["componentOptions"]),
        _createVNode(_component_OverviewIntegrationStatus, {
          componentOptions: _ctx.getComponentOptions('Integration Status')
        }, null, 8, ["componentOptions"]),
        _createVNode(_component_OverviewInventoryType, {
          componentOptions: _ctx.getComponentOptions('Inventory Type')
        }, null, 8, ["componentOptions"])
      ]))
    : _createCommentVNode("", true)
}