import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-153a0b40")
const _hoisted_1 = { class: "fs-card-title px-4 py-4" }
const _hoisted_2 = { class: "card-body flex-wrap w-100 p-0 overflow-hidden" }
const _hoisted_3 = { class: "col fs-4" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountUp = _resolveComponent("CountUp")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DashboardSkeleton = _resolveComponent("DashboardSkeleton")!

  return (_ctx.chartData && _ctx.componentOptions.options.isEnabled)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "flex-wrap mo-card w-100",
        style: _normalizeStyle({ 'order': _ctx.componentOptions.options.order })
      }, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.componentOptions.title), 1),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (status) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "text-center py-4 status",
              key: String(status)
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(status.name), 1),
              _createVNode(_component_router_link, {
                to: status.href
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CountUp, {
                    class: "h1 fw-bolder w-max-con",
                    end: status.value
                  }, null, 8, ["end"])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ], 4))
    : (_ctx.componentOptions.options.isEnabled)
      ? (_openBlock(), _createBlock(_component_DashboardSkeleton, {
          key: 1,
          options: [_ctx.componentOptions]
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
}