import { gql } from "graphql-tag";

const QUERY = gql`
    query {
        me {
            site {
                analytics {
                    activeOrders {
                        new
                        prepared
                        inProgress
                        pending
                        shippedToday
                    }
                }
            }
        }
    }
`;

const MAP = {
    new: {
        name: "New",
        value: 0,
        href: "/order-list/new/1",
    },
    prepared: {
        name: "Prepared",
        value: 0,
        href: "/order-list/prepared/1",
    },
    inProgress: {
        name: "In-Progress",
        value: 0,
        href: "/order-list/in-progress/1",
    },
    pending: {
        name: "Pending",
        value: 0,
        href: "/order-list/pending/1",
    },
    shippedToday: {
        name: "Shipped",
        value: 0,
        href: "/order-list/dispatched/1",
    },
};

export { QUERY, MAP };
