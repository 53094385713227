
import { defineComponent } from "vue";
import CountUp from "@components/Countup.vue";
import Utilities from '@utilities/Utilities';

export default defineComponent({
    name: "DonutChartLegend",
    components: { CountUp },
    props: ['chart', 'componentOptions', "options"],
    methods: {
        getColorsArray: (size: number): string[] => {
            return Utilities.getColors().getColorsArrayBySize(size);
        },
        getColorByIndex: (size: number, index: number): string => {
            return Utilities.getColors().getColorsByIndex(size, index);
        },
        handleGraphColumns(event: Event, index: number, series: string): void {
            const elm: any = this.$refs[`totalSalesPerChannelData-${index}`];
            const legendDisabledClass = "disabled";
            if (event.type === "click") {
                this.toggleSeries(elm, legendDisabledClass);
                this.chart.toggleSeries(series);
                return;
            }
            this.highlightSeries(elm, legendDisabledClass, event);
            return;
        },
        toggleSeries(element: HTMLElement, legendDisabledClass: string): void {
            element.classList.toggle(legendDisabledClass);
            return;
        },
        highlightSeries(element: HTMLElement, legendDisabledClass: string, event: Event): void {
            if (element.classList.contains(legendDisabledClass)) {
                return;
            }
            this.chart.highlightSeriesOnLegendHover(event, element);
            return;
        },
    },
});
