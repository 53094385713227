import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6b081626")
const _hoisted_1 = { class: "d-flex ms-4 me-2 overflow-auto chart-legends-holder" }
const _hoisted_2 = ["onClick", "onMouseout", "onMousemove", "rel", "seriesname"]
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "fs-5 fw-bolder ms-1" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountUp = _resolveComponent("CountUp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.seriesSorted, (channel, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "legend legend-border-right border-w#large dashed px-0 py-2 ps-4",
        key: index,
        onClick: ($event: any) => (_ctx.handleGraphColumns($event, index, channel.name)),
        onMouseout: ($event: any) => (_ctx.handleGraphColumns($event, index, channel.name)),
        onMousemove: ($event: any) => (_ctx.handleGraphColumns($event, index, channel.name)),
        rel: index + 1,
        seriesname: channel.name,
        ref: `totalSalesPerChannelData-${index}`
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "legend-icon m-1 ms-0",
            style: _normalizeStyle({ backgroundColor: _ctx.getColorByIndex(_ctx.options.series.length, index) })
          }, null, 4),
          _createElementVNode("div", _hoisted_4, _toDisplayString(channel.name), 1)
        ]),
        _createVNode(_component_CountUp, {
          class: "fs-5 fw-bolder",
          end: _ctx.getChannelTotal(channel.data),
          prefix: _ctx.options.currency,
          isDecimal: true
        }, null, 8, ["end", "prefix"])
      ], 40, _hoisted_2))
    }), 128))
  ]))
}