import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (block, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      (block.options.isEnabled)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName(_ctx.getComponentCategoryName(block.type)) + 'Imitation'), {
            key: 0,
            block: block,
            order: block.options.order,
            class: _normalizeClass(_ctx.getChartSize(block.options.chartSize, block.options.isChartLegendEnabled, _ctx.getComponentCategoryName(block.type))),
            style: _normalizeStyle({
                'order': block.options.order
            })
          }, null, 8, ["block", "order", "class", "style"]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}