
import { defineComponent } from "vue";

export default defineComponent({
    name: "ChartDownload",
    props: ['chart'],
    setup() {
        const originUrl = window.location.origin;

        return {
            originUrl,
        };
    },
    methods: {
        exportChartPNG: function (): void {
            const ctx = this.chart.ctx;
            ctx.exports.exportToPng(ctx);
            return;
        },
        exportChartCSV: function (): void {
            const ctx = this.chart.ctx;
            ctx.exports.exportToCSV({
                series: this.chart.w.config.series,
                columnDelimiter: ','
            });
            return;
        },
        exportChartSVG: function (): void {
            const ctx = this.chart.ctx;
            ctx.exports.exportToSVG(ctx);
            return;
        },
    },
});
