import { gql } from "graphql-tag";

const QUERY = gql`
    query {
        me {
            site {
                analytics {
                    activeOrderAge {
                        oneDay
                        twoDays
                        threePlusDays
                        overdue
                    }
                }
            }
        }
    }
`;

const MAP = {
    oneDay: "1 Day",
    twoDays: "2 Days",
    threePlusDays: "3+ Days",
    overdue: "Overdue",
};

export { QUERY, MAP };
