import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-672ea637")
const _hoisted_1 = { class: "w-100 px-2 py-0 me-8" }
const _hoisted_2 = { class: "w-100 d-flex mo-border-bottom-1 pb-2 me-2" }
const _hoisted_3 = { class: "w-50 text-start fw-bold gray" }
const _hoisted_4 = { class: "w-50 text-end fw-bold gray" }
const _hoisted_5 = { class: "w-100 mo-overflow-scroll max-height-16 remove-last-border" }
const _hoisted_6 = ["onClick", "onMouseout", "onMousemove", "rel", "seriesname"]
const _hoisted_7 = { class: "d-flex w-50 text-break align-items-center d-flex" }
const _hoisted_8 = { class: "ms-8 mb-0 h1 fs-5" }
const _hoisted_9 = { class: "d-flex w-50 text-break align-items-center text-end mb-0 h1 fs-5 me-2 justify-content-end" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountUp = _resolveComponent("CountUp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.options.headerLegendName), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.options.headerValueName), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.series, (value, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "w-100 d-flex py-4 position-relative mo-border-bottom-1 pointer table-legend",
          key: index,
          onClick: ($event: any) => (_ctx.handleGraphColumns($event, index, _ctx.options.labels[index])),
          onMouseout: ($event: any) => (_ctx.handleGraphColumns($event, index, _ctx.options.labels[index])),
          onMousemove: ($event: any) => (_ctx.handleGraphColumns($event, index, _ctx.options.labels[index])),
          rel: index + 1,
          seriesname: _ctx.options.labels[index],
          ref: `totalSalesPerChannelData-${index}`
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "legend-icon-table position-absolute top-50 start-0 translate-middle-y ms-2",
              style: _normalizeStyle({ backgroundColor: _ctx.getColorByIndex(_ctx.options.series.length, index) })
            }, null, 4),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.options.labels[index]), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_CountUp, {
              end: _ctx.options.series[index]
            }, null, 8, ["end"])
          ])
        ], 40, _hoisted_6))
      }), 128))
    ])
  ]))
}